<template>
    <!-- form -->
    <form class="validate-form">
    <!-- filter questions -->
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Permissions</h4>
        </template>
        <template #default>

                <div class="row">
                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" for="basicSelect">Team members</label>
                            <select class="form-select" id="basicSelect">
                                <option value="">Select team members</option>
                                <option v-for="member in teamMembers" :key="member.name" :value="member.name">
                                    {{ member.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" for="basicSelect">Permissions</label>
                            <select class="form-select" id="basicSelect">
                                <option value="">Select permission</option>
                                <option v-for="permission in permissionsList" :key="permission.assessment">
                                    {{ permission.assessment}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-3 col-lg-3 col-sm-12" >

                        <base-button
                            btnColor="primary"
                            btnType="outline"
                            :btnSelect="true"
                        >
                            Add permission
                        </base-button>
                    </div>

                </div>

        </template>
    </base-card-plain>
    <!-- / filter questions -->

    <base-card-plain>
            <template #header>
                <h4 class="card-title">Recruitment Team </h4>
            </template>
            <template #default>
                <table class="table table-bordered table-responsive">
                    <thead>
                        <tr>
                            <th class="qtr-td-col">Team member</th>
                            <th>Permissions</th>
                        </tr>
                    </thead>
                    <tbody class="">
                        <tr class="table-hover" v-for="member in teamMembers" :key="member.name">
                            <td>
                                <span class="fw-bold">{{ member.name }}</span>
                            </td>
                            <td>
                                <base-badge badgeColor="primary" v-for="perm in member.permissions" :key="perm">
                                    {{ perm }}
                                    <span> x </span>
                                </base-badge>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
    </base-card-plain>




    </form>
    <!--/ form -->
</template>

<script>
// import components
import { ref } from "vue";
import { useStore } from 'vuex';

export default{
    props:{
        currJobId:{
            default:"none",
            type: String,
            required: false
        }
    },
    setup(){

        const store = useStore();

        // use the dispatch function to call an action that calls a mutation
        // to set the data of for the job ad
        const oldJobData = ref(store.getters["recruitment/getJobAd"]);

        const teamMembers = ref([
            {name:"John Mugio", permissions:["Co-owner"]},
            {name:"Rose Asuwah", permissions:["Applicant_pool","Test"]},
            {name:"Thelma Mama", permissions:["Interview"]},
            {name:"Ebuka home", permissions:["Test","Interview"]},
            {name:"Sipi Riodert", permissions:["Applicant_pool","Test","Interview"]}
        ])

        const permissionsList = ref([
            {assessment:"Co-owner"},
            {assessment:"Applicant_pool"}
        ])

        permissionsList.value = permissionsList.value.concat(JSON.parse(oldJobData.value.assessments));

        return {
            teamMembers,
            permissionsList
        }
    }
}


</script>
