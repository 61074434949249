<template>
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Filter Questions</h4>
            <base-card-drop-down>
                <template #title>
                    Filter options
                </template>
                <template #default>
                    <a class="dropdown-item" href="#" @click="setActiveQuestionForm('create_filter_q')">
                        <i class="bi bs-icon-tiny"
                           :class="activeQuestionForm == 'create_filter_q'?'bi-check-circle':'bi-circle' " ></i>
                        Create new question
                    </a>
                    <a class="dropdown-item" href="#" @click="setActiveQuestionForm('existing_filter_q')">
                        <i class="bi bs-icon-tiny"
                           :class="activeQuestionForm == 'existing_filter_q'?'bi-check-circle':'bi-circle' " ></i>
                        Use existing question
                    </a>
                </template>
            </base-card-drop-down>
        </template>
        <template #default>
            <form class="validate-form" @submit.prevent="updateFilterQuestions">
                <div class="row" v-if="activeQuestionForm == 'existing_filter_q'">
                    <div class="col-12 col-lg-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label"
                                   :class="oldFilterQ.question.msg?'text-danger':''"
                                   for="existingQuestions">
                                Existing filter questions*
                                <span v-if="oldFilterQ.question.msg" > | {{oldFilterQ.question.msg}}</span>
                            </label>
                            <select class="form-select" id="existingQuestions"
                                    @change="validate.validateFormData(oldFilterQ,'question',false)"
                                    v-model="oldFilterQ.question.val"
                            >
                                <option value="">Select existing question</option>
                                <option v-for="filter in compExistingQuestions"
                                        :key="JSON.stringify(filter)"
                                        :value="JSON.stringify(filter)"
                                 >
                                    Q : {{ filter.question }} ( A: {{ filter.desiredResponse }} )
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="row" >
                        <div class="col-12" v-if="oldFilterQ.reqFeedback.msg">

                            <base-list-tag :tagColor="oldFilterQ.reqFeedback.val" >{{oldFilterQ.reqFeedback.msg}}</base-list-tag>
                        </div>


                        <div class="col-3 col-lg-3 col-sm-12 mb-3" v-if="oldFilterQ.showSubmit.val">

                            <base-button
                                btnColor="primary"
                                btnType="outline"
                                btnAction="button"
                                :btnSelect="true"
                                @click="addOldFilterQuestion"
                            >
                                Add question
                            </base-button>
                        </div>
                        <base-spinner  v-else></base-spinner>
                    </div>
                </div>


                <div class="row" v-if="activeQuestionForm == 'create_filter_q'">
                    <div class="col-12 col-lg-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="newFilterQ.question.msg?'text-danger':''" for="question">
                                Filter Question*
                                <span v-if="newFilterQ.question.msg" > | {{newFilterQ.question.msg}}</span>
                            </label>
                            <input type="text" class="form-control" @keyup="validate.validateFormData(newFilterQ,'question',false)"
                                   placeholder="Filter question" id="question" name="question"
                                   v-model="newFilterQ.question.val" />
                        </div>
                    </div>
                    <div class="col-12 col-lg-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="newFilterQ.responseOptions.msg?'text-danger':''" for="question">
                                Response options*
                                <span v-if="newFilterQ.responseOptions.msg" > | {{newFilterQ.responseOptions.msg}}</span>
                            </label>
                            <select class="form-select" id="responseType" @change="validate.validateFormData(newFilterQ,'responseOptions',false)"
                                    v-model="newFilterQ.responseOptions.val">
                                <option value="">Select</option>
                                <option v-for="response in responseType" :key="response" :value="responseOptions[response]">
                                    {{ response }} - ( {{ responseOptions[response].join(" / ") }} )
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-12 col-sm-12" v-if="newFilterQ.responseOptions.val">
                        <div class="mb-1">
                            <label class="form-label" :class="newFilterQ.desiredResponse.msg?'text-danger':''" for="desiredResponse">
                                Desired response*
                                <span v-if="newFilterQ.desiredResponse.msg" > | {{newFilterQ.desiredResponse.msg}}</span>
                            </label>
                            <select class="form-select" id="desiredResponse" @change="validate.validateFormData(newFilterQ,'desiredResponse',false)"
                                    v-model="newFilterQ.desiredResponse.val">
                                <option value="">Select</option>
                                <option v-for="desired in newFilterQ.responseOptions.val" :key="desired" :value="desired">
                                    {{ desired }}
                                </option>
                            </select>
                        </div>
                    </div>


                    <div class="row" >
                        <div class="col-12" v-if="newFilterQ.reqFeedback.msg">

                            <base-list-tag :tagColor="newFilterQ.reqFeedback.val" >{{newFilterQ.reqFeedback.msg}}</base-list-tag>
                        </div>


                        <div class="col-3 col-lg-3 col-sm-12 mb-3" v-if="newFilterQ.showSubmit.val">

                            <base-button
                                btnColor="primary"
                                btnType="outline"
                                btnAction="button"
                                :btnSelect="true"
                                @click="addNewFilterQuestion"
                            >
                                Add question
                            </base-button>
                        </div>
                        <base-spinner  v-else></base-spinner>
                    </div>

                </div>


                <div class="row mt-2" v-if="fv.jobAdQuestions.val.length > 0">
                    <h4 class="card-title" :class="draftStatus?'text-warning':''">
                        Saved filter questions
                        <span class="text-warning" style="font-size:12px;float:right" v-if="draftStatus">
                            you have unsaved changes*
                        </span>
                    </h4>
                    <div class="demo-spacing-0">
                        <base-list-tag v-for="filter in fv.jobAdQuestions.val"
                                       :key="filter" :tagColor="filter.status == 'draft'?'warning':'secondary' "  >
                            <div class="float-start" >
                                <b>Q:</b>
                                    {{ filter.question }}
                                <b>( A: {{ filter.desiredResponse}} )</b>
                            </div>
                            <span @click= "deleteFilterQuestion(filter.question)" type="button" class="btn-close text-warning"
                                  data-dismiss="alert" aria-label="Close">
                            </span>
                        </base-list-tag>
                    </div>
                    <div class="col-12 col-sm-12 mt-3 mb-2" >
                        <div class="form-check form-switch">
                            <input type="checkbox" value="yesiii"
                                   v-model="fv.automateScreening.val"
                             class="form-check-input" checked id="automateScreening" />
                            <label class="form-check-label" for="accountSwitch2">
                                Automate reject applicants who don't meet the criteria, and send them a rejection mail within 3 days.
                            </label>
                        </div>
                    </div>
                </div>


                <div class="row mt-2">
                    <div class="col-12" v-if="fv.reqFeedback.msg">

                        <base-list-tag :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                    </div>



                    <div class="col-12" v-if="fv.showSubmit.status">


                        <div class="col-12" v-if="fv.showSubmit.val">
                            <base-button btnColor="primary" >Save information</base-button>
                        </div>
                        <base-spinner  v-else></base-spinner>
                    </div>
                </div>

            </form>
        </template>
    </base-card-plain>
</template>

<script>
// import base ui components
import { ref, reactive, computed, inject } from 'vue';
import { useStore } from 'vuex';
import validators from '@/hooks/validation';

export default{
    props:{
        currJobId:{
            default:"none",
            type: String,
            required: false
        }
    },
    setup(props){

        // get the axios object
        const axios = inject('axios');  // inject axios

        // get the global vuex store values
        const store = useStore();

        // use the dispatch function to call an action that calls a mutation
        // to set the data of for the job ad
        const oldJobData = ref(store.getters["recruitment/getJobAd"]);
        const existingFilterQ = ref(oldJobData.value.filters);

        //get the urls
        const coreUrl = inject('coreUrl');

        // set the validators function
        const validate = validators();

        // set the calue of the request Error
        const reqError = ref("Loading data ....");

        // form values to be used in this form
        const oldFilterQ = reactive({
            question : {status:false, val:"", msg:false, validate:["required"]},
            reqFeedback : {status:false, val:"", msg:false},
            showSubmit : {status:false, val:true}
        });

        // form values to be used in this form
        const newFilterQ = reactive({
            question : {status:false, val:"", msg:false, validate:["required","safeSpaceComma"]},
            responseOptions : {status:false, val:"", msg:false, validate:["required"]},
            desiredResponse : {status:false, val:"", msg:false, validate:["required","safeSpaceInput"]},
            reqFeedback : {status:false, val:"", msg:false},
            showSubmit : {status:false, val:true}
        });

        const draftStatus = ref(false);

        // form values to be used in this form
        const fv = reactive({
            jobAdQuestions : {status:false, val:oldJobData.value.filters?JSON.parse(oldJobData.value.filters):[], msg:false, validate:["required"]},
            existingQuestions : {status:false, val:[], msg:false, validate:["required"]},
            automateScreening : {status:false, val:oldJobData.value.automate_screening == 'yes'?true:false, msg:false, validate:["safeInput"]},
            reqFeedback : {status:false, val:"", msg:false},
            showSubmit : {status:false, val:true}
        });


        // when the vue module is created, get the current profile data, using the try catch
        axios.get(
            coreUrl.backendUrl+'get_existing_filter_questions',
            {withCredentials: true}).then((response) =>{

            // return the data to result and update existing data variable
            const result = response.data.data.filters;
            fv.existingQuestions.val = result?JSON.parse(result):[];

        }).catch((error)=>{
            console.log(error)
            let errorMsg = "Can't load location data at this time."
            reqError.value = error.response.data.message.req_msg?errorMsg:errorMsg;
        })


        const responseType = ref(["affirmative", "proficiency", "level","gender"]);

        const responseOptions = ref({
            affirmative:["yes","no"],
            proficiency:["beginner","intermediate","expert"],
            level:["junior","mid-level","senior"],
            gender:["female","male","anonymous"]
        });

        const activeQuestionForm = ref("create_filter_q");

        function setActiveQuestionForm(questionForm){
            activeQuestionForm.value = questionForm;
        }

        function addNewFilterQuestion(){

            newFilterQ.showSubmit.val = false;

            let duplicateExistingQuestion = fv.existingQuestions.val;
            let duplicateNewQuestion = fv.jobAdQuestions.val.findIndex(item => item.question === newFilterQ.question.val) < 0 ? true:false;

            if(newFilterQ.question.status && newFilterQ.responseOptions.status &&
                newFilterQ.desiredResponse.status && duplicateNewQuestion){

                let question = newFilterQ.question.val;
                let responseOptions = newFilterQ.responseOptions.val;
                let desiredResponse = newFilterQ.desiredResponse.val;
                let status = "draft";

                const newQuestion = {question,responseOptions,desiredResponse,status}
                fv.jobAdQuestions.val.push(newQuestion)
                duplicateExistingQuestion?fv.existingQuestions.val.push(newQuestion):"";

                fv.showSubmit.status = true;
                newFilterQ.showSubmit.val = true;
                newFilterQ.reqFeedback.msg = "";

                draftStatus.value = true;

                fv.reqFeedback.msg = "";
            }else{
                newFilterQ.showSubmit.val = true;
                newFilterQ.reqFeedback.status = true;
                newFilterQ.reqFeedback.val = "danger";
                newFilterQ.reqFeedback.msg = "Kindly enter the appropriate values correctly";
                newFilterQ.question.msg = duplicateNewQuestion?"":"You cannot enter a duplicate question";
            }
        }

        const compExistingQuestions = computed(function(){
            let existingQuestions = fv.existingQuestions.val.filter(item => item.status !== "draft");
            return existingQuestions;
        })

        function addOldFilterQuestion(){

            oldFilterQ.showSubmit.val = false;

            let questionSet = JSON.parse(oldFilterQ.question.val)

            questionSet.status = "draft";

            let duplicateOldQuestion = fv.jobAdQuestions.val.findIndex(item => item.question === questionSet.question) < 0 ? true:false;

            if(oldFilterQ.question.status && duplicateOldQuestion){

                fv.jobAdQuestions.val.push(questionSet);

                fv.showSubmit.status = true;
                oldFilterQ.showSubmit.val = true;
                oldFilterQ.reqFeedback.msg = "";
                fv.reqFeedback.msg = "";

                draftStatus.value = true;
            }else{
                oldFilterQ.showSubmit.val = true;
                oldFilterQ.reqFeedback.status = true;
                oldFilterQ.reqFeedback.val = "danger";
                oldFilterQ.reqFeedback.msg = "Kindly select a different question, or create a new one";
                oldFilterQ.question.msg = duplicateOldQuestion?"":"You cannot enter a duplicate question";
            }
        }

        function deleteFilterQuestion(question){

            fv.jobAdQuestions.val = fv.jobAdQuestions.val.filter(item => item.question !== question);

            fv.existingQuestions.val = fv.existingQuestions.val.filter(function(item){

                if(item.question === question && item.status === "draft"){
                    return false;
                }

                return true;

            });

            draftStatus.value = existingFilterQ.value == JSON.stringify(fv.jobAdQuestions.val) ? false : true;
        }


        // start create  JD
        async function updateFilterQuestions(){

            // start the action of making a submission, and show loader
            fv.showSubmit.val = false;

            let jobAdQuestions = JSON.stringify(fv.jobAdQuestions.val);
            let cleanJobAdQuestions = jobAdQuestions.replace(/draft/g,'active');
            let existingQuestions = JSON.stringify(fv.existingQuestions.val);
            let cleanExistingQuestions = existingQuestions.replace(/draft/g,'active');

            // load the form data , and append all the necessary fields
            let formData = new FormData();
            formData.append("fv_jobAdQuestions", cleanJobAdQuestions);
            formData.append("fv_existingQuestions", cleanExistingQuestions);
            formData.append("fv_automateScreening", fv.automateScreening.val ? "yes" : "no");

            // post the data using axios
            try{

                const response = await axios.put(
                    coreUrl.backendUrl+'update_job_ad_filter_questions/'+props.currJobId,
                    formData,
                    {
                        withCredentials: true,
                        headers:{
                           "Content-Type": "multipart/form-data"
                        }
                    }
                );

                // If successful, get the resppnse data
                const responseData = response.data;


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = responseData.message.req_msg;
                fv.reqFeedback.val = "success";
                fv.showSubmit.status = false;

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;
                fv.jobAdQuestions.val = JSON.parse(cleanJobAdQuestions);
                fv.existingQuestions.val = JSON.parse(cleanExistingQuestions);
                existingFilterQ.value = cleanJobAdQuestions;

                draftStatus.value = false;


            }catch(error){

                // load the error to the appropriate field
                const fv_msg = error.response.data.message.fv_msg;
                const req_msg = error.response.data.message.req_msg;

                for(const key in fv_msg){
                    if(fv[key]){
                        fv[key]["status"] = false;
                        fv[key]["msg"] = fv_msg[key];
                    }
                }


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = req_msg;
                fv.reqFeedback.val = "danger";

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;

            }

        }
        // end edit JD


        return {
            fv,
            validate,
            oldFilterQ,
            newFilterQ,
            draftStatus,
            responseOptions,
            responseType,
            activeQuestionForm,
            setActiveQuestionForm,
            compExistingQuestions,
            addNewFilterQuestion,
            addOldFilterQuestion,
            deleteFilterQuestion,
            updateFilterQuestions
        }
    }
}


</script>
