<template>
    <!-- core job detail -->
    <base-card-plain>
        <template #header>
            <h4 class="card-title col-10" >Job title & Overview</h4>
            <span class="me-1 text-primary cursor-pointer" v-if="hideJobDetails">hide job details</span>
            <base-card-drop-down v-else>
                <template #title>
                    JD options
                </template>
                <template #default>
                    <a class="dropdown-item" href="#">
                        <i class="bi bi-circle bs-icon-tiny" ></i>
                        Create JD from scratch
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="bi bi-circle bs-icon-tiny" ></i>
                        Duplicate existing JD
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="bi bi-check-circle bs-icon-tiny" ></i>
                        Load existing JD
                    </a>
                </template>
            </base-card-drop-down>
        </template>
        <template #default>
            <p class="card-text">
                Some quick example text to build on the card title and make up the bulk of the card's content.
                Some quick example text to build on the card title and make up the bulk of the card's content.
                Some quick example text to build on the card title and make up the bulk of the card's content.
                Some quick example text to build on the card title and make up the bulk of the card's content.
                Some quick example text to build on the card title and make up the bulk of the card's content.
            </p>
            <h4 class="card-title mt-3">Responsibilities</h4>
            <p class="card-text">
                <ul>
                    <li class="mt-2"><i class="bi bi-chevron-double-right bs-icon-tiny" ></i>
                        Some quick example text to build on the card title and make up the bulk of the card's content.
                    </li>
                    <li class="mt-2"><i class="bi bi-chevron-double-right bs-icon-tiny" ></i>
                        Some quick example text to build on the card title and make up the bulk of the card's content.
                    </li>
                    <li class="mt-2"><i class="bi bi-chevron-double-right bs-icon-tiny" ></i>
                        Some quick example text to build on the card title and make up the bulk of the card's content.
                    </li>
                </ul>

            </p>
            <h4 class="card-title mt-3">Qualifications</h4>
            <p class="card-text">
                <ul>
                    <li class="mt-2"><i class="bi bi-calendar-event-fill bs-icon-tiny" ></i> At least 7 years experience required </li>
                    <li class="mt-2"><i class="bi bi-bookmark-star-fill bs-icon-tiny" ></i> At least, a dilpoma in any of the following courses is required: Computer Science, Geography</li>
                    <li class="mt-2"><i class="bi bi-chat-quote bs-icon-tiny" ></i> Fluency in English,French is required</li>
                    <li class="mt-2"><i class="bi bi-globe bs-icon-tiny" ></i> Work experience in Nigeria, Ghana is required</li>
                </ul>
            </p>
            <h4 class="card-title mt-3">Skills required</h4>
            <base-badge  badgeColor="primary">Active skill</base-badge>
            <base-badge  badgeColor="primary">Active skill</base-badge>
            <base-badge  badgeColor="primary">Active skill</base-badge>
            <base-badge  badgeColor="primary">Active skill</base-badge>
            <base-badge  badgeColor="primary">Active skill</base-badge>
            <base-badge  badgeColor="primary">Active skill</base-badge>


        </template>
    </base-card-plain>
    <!--Core job detail -->
</template>

<script>
export default{
    props:{
        hideJobDetails:{
            type:Boolean,
            required:false,
            default:false
        }
    }
}
</script>



<style scoped>
ul {
    list-style-type: none;
    padding:0px;
}

li {
    margin:15px 0px;
}

li i{
    margin-right:0.7rem;
}

.inline-small-spacing{
    margin-top:0px;
    margin-bottom:0.7rem;
}
</style>
