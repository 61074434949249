<template>


    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper container-xxl p-0">
            <div class="content-header row">
                <div class="content-header-left col-md-9 col-12 mb-2">
                    <div class="row breadcrumbs-top">
                        <div class="col-12 mt-2">
                            <h2 class="content-header-title float-start mb-0">UPDATE JOB AD</h2>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">{{ jobTitle }}</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-body" v-if="existingData">
                <!-- account setting page -->
                <section id="page-account-settings">
                    <div class="row">
                        <!-- left menu section -->
                        <div class="col-md-3 mb-2 mb-md-0">
                            <ul class="nav nav-pills flex-column nav-left">
                                <!-- Job description -->
                                <li class="nav-item">
                                    <a class="nav-link active" id="post-job-pill-jd" data-bs-toggle="pill" href="#post-job-jd" aria-expanded="true">
                                        <i class="bi bi-briefcase bs-icon-btn-tiny font-medium-3 mb-1 me-2" ></i>
                                        <span class="fw-bold">Job Description</span>
                                    </a>
                                </li>
                                <!-- Options -->
                                <li class="nav-item" >
                                    <a class="nav-link" id="post-job-pill-options" data-bs-toggle="pill" href="#post-job-options" aria-expanded="false">
                                        <i class="bi bi-sliders bs-icon-btn-tiny font-medium-3 mb-1 me-2" ></i>
                                        <span class="fw-bold">Job options</span>
                                    </a>
                                </li>
                                <!-- Config -->
                                <li class="nav-item" v-if="advancedOptions">
                                    <a class="nav-link" id="post-job-pill-filters" data-bs-toggle="pill" href="#post-job-filters" aria-expanded="false">
                                        <i class="bi bi-funnel-fill bs-icon-btn-tiny font-medium-3 mb-1 me-2" ></i>
                                        <span class="fw-bold">Filters</span>
                                    </a>
                                </li>

                                <!-- post-job-pill-accessment -->
                                <li class="nav-item" v-if="advancedOptions">
                                    <a class="nav-link" id="post-job-pill-accessment" data-bs-toggle="pill" href="#post-job-accessment" aria-expanded="false">
                                        <i class="bi bi-ui-checks-grid bs-icon-btn-tiny font-medium-3 mb-1 me-2" ></i>
                                        <span class="fw-bold ">Accessment</span>
                                    </a>
                                </li>
                                <!-- post-job-pill-permissions -->
                                <li class="nav-item" v-if="advancedOptions">
                                    <a class="nav-link" id="post-job-pill-permissions" data-bs-toggle="pill" href="#post-job-permissions" aria-expanded="false">
                                        <i class="bi bi-shield-lock bs-icon-btn-tiny font-medium-3 mb-1 me-2" ></i>
                                        <span class="fw-bold">Permissions</span>
                                    </a>
                                </li>
                                <!-- post-job-pill-permissions -->
                                <li class="nav-item" v-if="thirdPartyOption">
                                    <a class="nav-link" id="post-job-pill-3rdparty" data-bs-toggle="pill" href="#post-job-3rdparty" aria-expanded="false">
                                        <i class="bi bi-people bs-icon-btn-tiny font-medium-3 mb-1 me-2" ></i>
                                        <span class="fw-bold">Third party</span>
                                    </a>
                                </li>
                                <!-- post-job-pill-publish -->
                                <li class="nav-item" >
                                    <a class="nav-link" id="post-job-pill-publish" data-bs-toggle="pill" href="#post-job-publish" aria-expanded="false">
                                        <i class="bi bi-megaphone-fill bs-icon-btn-tiny font-medium-3 mb-1 me-2" ></i>
                                        <span class="fw-bold">Publish</span>
                                    </a>
                                </li>
                            </ul>
                            <div class="form-check form-switch mt-3">
                                <input type="checkbox" class="form-check-input"
                                    @click="toggleAdvancedOptions" id="toggleAdvancedOptions" />
                                <label class="form-check-label" for="toggleAdvancedOptions">
                                    Advanced
                                </label>
                            </div>
                            <div class="form-check form-switch mt-2">
                                <input type="checkbox" class="form-check-input"
                                       @click="toggleThirdPartyOption" id="thirdPartyOption" />
                                <label class="form-check-label" for="thirdPartyOption">
                                    3rd party
                                </label>
                            </div>
                        </div>
                        <!--/ left menu section -->

                        <!-- right content section -->
                        <div class="col-md-9">
                            <div class="card">
                                <div class="card-body">
                                    <div class="tab-content">
                                        <!-- post-job-pill-jd -->
                                        <div role="tabpanel" class="tab-pane active" id="post-job-jd" aria-labelledby="post-job-pill-jd" aria-expanded="true">


                                            <create-job-description :isJobAd="true"
                                                                    :currJobId="jobId"
                                                                    :isUpdateJobAd="true"
                                                                    v-if="existingData">
                                            </create-job-description>


                                            <recruitment-view-job v-if="false">
                                            </recruitment-view-job>


                                        </div>
                                        <!--/ post-job-pill-jd -->

                                        <!-- post-job-pill-option -->
                                        <div class="tab-pane fade" id="post-job-options" role="tabpanel" aria-labelledby="post-job-pill-options" aria-expanded="false">
                                            <!-- contract type & enumeration -->
                                            <create-job-options :currJobId="jobId" v-if="existingData" >
                                            </create-job-options>
                                            <!-- / contract type & enumeration -->
                                        </div>
                                        <!--/ post-job-pill-option -->

                                        <!-- post-job-pill-filters -->
                                        <div class="tab-pane fade" id="post-job-filters" role="tabpanel" aria-labelledby="post-job-pill-filters" aria-expanded="false">
                                            <!-- filter questions -->
                                            <create-job-filter-questions  :currJobId="jobId" v-if="existingData">
                                            </create-job-filter-questions>
                                            <!-- / filter questions -->
                                        </div>
                                        <!--/ post-job-pill-filters -->


                                        <!-- post-job-pill-accessment -->
                                        <div class="tab-pane fade" id="post-job-accessment" role="tabpanel" aria-labelledby="post-job-pill-accessment" aria-expanded="false">

                                            <!-- form -->
                                            <create-job-accessment-config  :currJobId="jobId" v-if="existingData">
                                            </create-job-accessment-config>
                                            <!--/ form -->

                                        </div>
                                        <!--/ post-job-pill-accessment -->

                                        <!-- post-job-pill-permissions -->
                                        <div class="tab-pane fade" id="post-job-permissions" role="tabpanel" aria-labelledby="post-job-pill-permissions" aria-expanded="false">
                                            <!-- form -->
                                            <create-job-permissions  :currJobId="jobId" v-if="existingData">
                                            </create-job-permissions>
                                            <!--/ form -->
                                        </div>
                                        <!--/ post-job-pill-permissions -->

                                        <!-- post-job-pill-3rdparty -->
                                        <div class="tab-pane fade" id="post-job-3rdparty" role="tabpanel" aria-labelledby="post-job-pill-3rdparty" aria-expanded="false">
                                            <!-- form -->
                                            <create-job-set-third-party>
                                            </create-job-set-third-party>
                                            <!--/ form -->
                                        </div>
                                        <!--/ post-job-pill-3rdparty -->



                                        <!-- post-job-pill-accessment -->
                                        <div class="tab-pane fade" id="post-job-publish" role="tabpanel" aria-labelledby="post-job-pill-publish" aria-expanded="false">
                                            <!-- form -->
                                            <create-job-publish  :currJobId="jobId" v-if="existingData">
                                            </create-job-publish>
                                            <!--/ form -->
                                        </div>
                                        <!--/ post-job-pill-accessment -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--/ right content section -->
                    </div>
                </section>
                <!-- / account setting page -->

            </div>

            <!-- End the section for main form -->

            <base-card-response class="mt-1" cardType="secondary" v-else>

                <template #header></template>
                <template #default>
                    <p>
                        {{reqError}}
                    </p>
                </template>

            </base-card-response>


        </div>
    </div>
    <!-- END: Content-->


</template>

<script>
// import the core functions from vuex
import { ref, inject } from 'vue';
import { useStore } from 'vuex';

import CreateJobDescription from '@/components/forms/recruitment/CreateJobDescription.vue';
import CreateJobOptions from '@/components/forms/recruitment/CreateJobOptions.vue';
import CreateJobFilterQuestions from '@/components/forms/recruitment/CreateJobFilterQuestions.vue';
import CreateJobAccessmentConfig from '@/components/forms/recruitment/CreateJobAccessmentConfig.vue';
import CreateJobPermissions from '@/components/forms/recruitment/CreateJobPermissions.vue';
import CreateJobSetThirdParty from '@/components/forms/recruitment/CreateJobSetThirdParty.vue';
import CreateJobPublish from '@/components/forms/recruitment/CreateJobPublish.vue';
import RecruitmentViewJob from '@/components/recruitment/RecruitmentViewJob.vue';


export default{
    components:{
        CreateJobDescription,
        CreateJobOptions,
        CreateJobFilterQuestions,
        CreateJobAccessmentConfig,
        CreateJobSetThirdParty,
        CreateJobPermissions,
        CreateJobPublish,
        RecruitmentViewJob
    },
    props:{
      jobId:{
          default: "none",
          type: String,
          required: true
      }
    },
    setup(props){
        // get the global vuex store values
        const store = useStore();

        // get the axios object
        const axios = inject('axios');  // inject axios

        //get the urls
        const coreUrl = inject('coreUrl');

        // use the dispatch function to call an action that calls a mutation
        store.dispatch("globals/executeSetNavMenu",true);

        // set the default value for JD that has been created
        const isJDCreated = ref(false);

        // set the default data for advance settings
        const advancedOptions = ref(false);


        function toggleAdvancedOptions(){
            advancedOptions.value = !advancedOptions.value;
        }

        // set the default data for advance settings
        const thirdPartyOption = ref(false);

        function toggleThirdPartyOption(){
            thirdPartyOption.value = !thirdPartyOption.value;
        }


        const jobTitle  = ref("");

        const existingData  = ref(false);
        const reqError  = ref("Loading data ....");


            // use axios to get the data
            axios.get(
                coreUrl.backendUrl+'get_current_job_ad/'+props.jobId,
                {withCredentials: true}).then((response) =>{

                const result = response.data.data;

                // use the dispatch function to call an action that calls a mutation
                // to set the data of for the job ad
                store.dispatch("recruitment/executeSetJobAd",result);
                store.getters["recruitment/getJobAd"]

                existingData.value = result;

                jobTitle.value = result.title;

            }).catch((error)=>{
                    let errorMsg = "We could not fetch your data at this time"
                    reqError.value = error.response.data.message.req_msg?errorMsg:errorMsg;
                    existingData.value = false;
                    console.log(reqError.value);
            })


        return {
            advancedOptions,
            toggleAdvancedOptions,
            thirdPartyOption,
            isJDCreated,
            toggleThirdPartyOption,
            existingData,
            reqError,
            jobTitle
        }
    }
}


</script>

<style scoped>
.bs-icon-small{
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1.5rem;
}

.bs-icon-tiny{
    height: 0.5rem;
    width: 0.5rem;
    font-size: 0.5rem;
    margin-right: 1.1rem;
    flex-shrink: 0;}


</style>
