<template>
    <!-- form -->
    <form class="validate-form" @submit.prevent="updatePublishJob">
    <!-- filter questions -->
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Publish job ad</h4>
        </template>
        <template #default>
            <div class="row">
                <div class="col-12 col-sm-12">

                    <div class="mb-1">
                        <label class="form-label" :class="fv.publishStatus.msg?'text-danger':''" for="publishingOptions">
                            Do you want to publish this job? *
                            <span v-if="fv.publishStatus.msg" > | {{fv.publishStatus.msg}}</span>
                        </label>
                        <select class="form-select" id="publishingOptions"
                                @change="validate.validateFormData(fv,'publishStatus',true)"
                                v-model="fv.publishStatus.val">
                            <option value="">Select</option>
                            <option v-for="option in publishingOptions"
                                    :key="option.value"
                                    :value="option.value">
                                    {{ option.alias }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row" v-if="fv.publishStatus.val == 'published'">
                    <div class="col-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.startDate.msg?'text-danger':''" for="startDate">
                                Select start date? *
                                <span v-if="fv.startDate.msg" > | {{fv.startDate.msg}}</span>
                            </label>
                            <div class="col-12 col-sm-12">
                                <Datepicker :is24="false" v-model="fv.startDate.val" :format="dateStringFormat">
                                </Datepicker>
                            </div>
                        </div>
                    </div>

            </div>


            <div class="row" v-if="fv.publishStatus.val == 'published'">
                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.adDuration.msg?'text-danger':''" for="adDuration">
                                Duration *
                                <span v-if="fv.adDuration.msg" > | {{fv.adDuration.msg}}</span>
                            </label>
                            <select class="form-select"
                                    @change="validate.validateFormData(fv,'adDuration',true)"
                                    id="adDuration" v-model="fv.adDuration.val">
                                <option value="">Select</option>
                                <option v-for="dur in adDuration" :key="dur" :value="dur">
                                    {{ dur }} days
                                </option>
                            </select>
                        </div>
                    </div>

            </div>

            <div class="row" v-if="fv.publishStatus.val == 'published'">
                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" for="eligibleApplicants" :class="fv.eligibleApplicants.msg?'text-danger':''">
                                Who would you like to apply for this post?
                                <span v-if="fv.eligibleApplicants.msg" > | {{fv.eligibleApplicants.msg}}</span>
                            </label>
                            <select class="form-select" id="eligibleApplicants"
                                    @change="validate.validateFormData(fv,'eligibleApplicants',true)"
                                    v-model="fv.eligibleApplicants.val"
                            >
                                <option value="">Select</option>
                                <option v-for="eligible in eligibleApplicants"
                                        :key="eligible"
                                        :value="eligible.value"
                                    >
                                        {{ eligible.alias }}
                                </option>
                            </select>
                        </div>
                    </div>

            </div>


            <div class="col-12" v-if="fv.reqFeedback.msg">

                    <base-list-tag :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
            </div>


            <div class="col-12" v-if="fv.showSubmit.val">
                    <base-button btnColor="primary" v-if="fv.publishStatus.val">
                        <span v-if="fv.publishStatus.val == 'published'"> Publish job ad </span>
                        <span v-else> Save job ad in draft </span>
                    </base-button>
            </div>
            <base-spinner  v-else></base-spinner>



        </template>
    </base-card-plain>
    <!-- / filter questions -->






    </form>
    <!--/ form -->
</template>

<script>
// import components
import { ref,reactive,inject } from 'vue';
import { useStore } from 'vuex';
import validators from '@/hooks/validation';
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';

export default{
    props:{
        currJobId:{
            default:"none",
            type: String,
            required: false
        }
    },
    components: { Datepicker },
    setup(props){

        // get the axios object
        const axios = inject('axios');  // inject axios

        //get the urls
        const coreUrl = inject('coreUrl');

        // set the validators function
        const validate = validators();

        // get the global vuex store values
        const store = useStore();

        // use the dispatch function to call an action that calls a mutation
        // to set the data of for the job ad
        const oldJobData = ref(store.getters["recruitment/getJobAd"]);

        const publishingOptions = [
            {value:"published",alias:"Publish"},
            {value:"drafted",alias:"Draft"}
        ];

        const monthList = [ "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];


        const dateStringFormat = (newDate) => {
            const minute = newDate.getMinutes();
            const hour = newDate.getHours();
            const day = newDate.getDate();
            const month = newDate.getMonth() + 1;
            const year = newDate.getFullYear();

            const period = hour > 12 ? "pm" : "am";

            fv.startDate.val = `${year}-${month}-${day} ${hour}:${minute}:30.139763`;

            return `Job ad to be published by ${hour}:${minute} ${period} on ${day}-${monthList[month -1]}-${year}`;
        }


        const adDuration = [7,10,14,20,28]



        const eligibleApplicants = [
            {value:"everyone",alias:"Everyone"},
            {value:"selected_applicants",alias:"Selected applicants"},
            {value:"internal_talent_pool",alias:"Selected applicants from internal talent pool"},
            {value:"all_talent_pool",alias:"Selected applicants from all talent pool"}
        ];


        // form values to be used in this form
        const fv = reactive({
            publishStatus : {status:false, val:oldJobData.value.publish_status, msg:false, validate:["required","safeInput"]},
            startDate : {status:false, val:oldJobData.value.start_date, msg:false, validate:["required"]},
            adDuration: {status:false, val:oldJobData.value.duration?parseInt(oldJobData.value.duration):"", msg:false, validate:["required","numeric"]},
            eligibleApplicants: {status:false, val:oldJobData.value.eligible_applicants, msg:false, validate:["required"]},
            reqFeedback : {status:false, val:"", msg:false},
            showSubmit : {status:false, val:true}
        });


        // start create  JD
        function updatePublishJob(){

            // start the action of making a submission, and show loader
            fv.showSubmit.val = false;

            // load the form data , and append all the necessary fields
            let formData = new FormData();
            formData.append("fv_publishStatus", fv.publishStatus.val);
            formData.append("fv_startDate", fv.publishStatus.val=="published"?fv.startDate.val:"none");
            formData.append("fv_adDuration", fv.publishStatus.val=="published"?fv.adDuration.val:1);
            formData.append("fv_eligibleApplicants", fv.publishStatus.val=="published"?fv.eligibleApplicants.val:"none");

            const setJobOptions = oldJobData.value.set_job_options;

            if(setJobOptions == 'yes'){
                publishJobAdAction(formData)
            }else{
                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = "You have to setup up the job options befoe you publish a job";
                fv.reqFeedback.val = "danger";

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;
            }


        }
        // end edit JD


        async function publishJobAdAction(formData){
            // post the data using axios
            try{

                const response = await axios.put(
                    coreUrl.backendUrl+'update_job_ad_publish_job/'+props.currJobId,
                    formData,
                    {
                        withCredentials: true,
                        headers:{
                           "Content-Type": "multipart/form-data"
                        }
                    }
                );

                // If successful, get the resppnse data
                const responseData = response.data;


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = responseData.message.req_msg;
                fv.reqFeedback.val = "success";
                fv.showSubmit.status = false;

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;


            }catch(error){

                // load the error to the appropriate field
                const fv_msg = error.response.data.message.fv_msg;
                const req_msg = error.response.data.message.req_msg;

                for(const key in fv_msg){
                    if(fv[key]){
                        fv[key]["status"] = false;
                        fv[key]["msg"] = fv_msg[key];
                    }
                }


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = req_msg;
                fv.reqFeedback.val = "danger";

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;

            }
        }





        return {
            fv,
            validate,
            dateStringFormat,
            publishingOptions,
            adDuration,
            eligibleApplicants,
            updatePublishJob
        }
    }
}


</script>

<style scoped>
.date-border{
    border-width: 0px;
}

.all-date-border{
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
}
</style>
