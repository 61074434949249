<template>
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Job Options</h4>
        </template>
        <template #default>

            <form class="validate-form" @submit.prevent="updateJobOptions">

                <div class="row">
                    <div class="col-12 col-lg-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.jobLocation.msg?'text-danger':''" for="jobLocation">
                                Job Location*
                                <span v-if="fv.jobLocation.msg" > | {{fv.jobLocation.msg}}</span>
                            </label>
                            <select class="form-select" id="jobLocation"
                                    @keyup="validate.validateFormData(fv,'jobLocation',true)"
                                    v-model="fv.jobLocation.val">
                                <option value="">Select location</option>
                                <option v-for="location in locationOptions"
                                        :key="location.name"
                                        :value="JSON.stringify(location)"
                                 >
                                        {{location.name}}
                                </option>
                            </select>
                        </div>
                    </div>


                    <div class="col-12 col-lg-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" for="job_type">Job type</label>
                            <select class="form-select" id="job_type"
                                    @keyup="validate.validateFormData(fv,'jobType',true)"
                                    v-model="fv.jobType.val">
                                <option value="">Select job type</option>
                                <option v-for="option in jobTypeOptions"
                                        :key="option.value"
                                        :value="option.value"
                                 >
                                        {{option.alias}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 col-lg-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" for="salary_options">Salary status</label>
                            <select class="form-select" id="salary_options"
                                    @keyup="validate.validateFormData(fv,'salaryStatus',true)"
                                    v-model="fv.salaryStatus.val">
                                <option value="">Select</option>
                                <option value="disclose_salary">Disclose salary</option>
                                <option value="request_salary">Request applicants to quote salary</option>
                                <option value="none">None of the above</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-12" v-if="fv.jobType.val && fv.salaryStatus.val == 'disclose_salary'">
                        <div class="mb-1">
                            <label class="form-label" for="salary" :class="fv.salary.msg?'text-danger':''" >
                                Salary ( {{ placeholderOptions[fv.jobType.val] }} )
                                <span v-if="fv.salary.msg" > | {{fv.salary.msg}}</span>
                                <span v-if="fv.currency.msg" >  | {{fv.currency.msg}}</span>
                            </label>
                            <select class="form-select" id="currency"
                                    @keyup="validate.validateFormData(fv,'currency',true)"
                                    v-model="fv.currency.val">
                                <option value="">Select</option>
                                <option v-for="currency in currencyList" :key="currency.value" :value="currency.value">
                                    {{ currency.alias }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-8 col-lg-8 col-sm-12" v-if="fv.jobType.val && fv.salaryStatus.val == 'disclose_salary' ">
                        <div class="mb-1">
                            <label class="form-label" for="currency">.</label>
                            <input type="text" class="form-control " v-model="fv.salary.val"
                                   @keyup="validate.validateFormData(fv,'salary',true)"
                                   :placeholder="'Enter '+placeholderOptions[fv.jobType.val]" id="salary" name="salary" />
                        </div>
                    </div>




                    <div class="col-12" v-if="fv.reqFeedback.msg">

                        <base-list-tag :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                    </div>





                    <div class="col-12" v-if="fv.showSubmit.val">
                        <base-button btnColor="primary" >Save information</base-button>
                    </div>
                    <base-spinner  v-else></base-spinner>

                </div>

            </form>
        </template>
    </base-card-plain>
</template>

<script>
import {ref,reactive,inject} from 'vue';
import { useStore } from 'vuex';
import validators from '@/hooks/validation';

export default{
    props:{
        currJobId:{
            default:"none",
            type: String,
            required: false
        }
    },
    setup(props){

        // get the axios object
        const axios = inject('axios');  // inject axios

        // get the global vuex store values
        const store = useStore();

        // use the dispatch function to call an action that calls a mutation
        // to set the data of for the job ad
        const oldJobData = ref(store.getters["recruitment/getJobAd"]);

        //get the urls
        const coreUrl = inject('coreUrl');

        // set the validators function
        const validate = validators();


        // set the calue of the request Error
        const reqError = ref("Loading data ....");

        //get the data for location details
        const locationOptions = ref(JSON.parse(oldJobData.value.job_location));

        // when the vue module is created, get the current profile data, using the try catch
        axios.get(
            coreUrl.backendUrl+'get_org_profile_data',
            {withCredentials: true}).then((response) =>{

            // return the data to result and update existing data variable
            const result = response.data.data;

            locationOptions.value = JSON.parse(result.location_details);

        }).catch((error)=>{
            reqError.value = error.response.data.message?error.response.data.message:"can't fetch location data at this time";

        })

        // form values to be used in this form
        const fv = reactive({
            jobLocation : {status:false, val:oldJobData.value.job_location?oldJobData.value.job_location:"", msg:false, validate:["required"]},
            jobType : {status:false, val:oldJobData.value.job_type?oldJobData.value.job_type:"", msg:false, validate:["required"]},
            salaryStatus: {status:false, val:oldJobData.value.salary_status?oldJobData.value.salary_status:"", msg:false, validate:["required"]},
            salary: {status:false, val:oldJobData.value.salary?oldJobData.value.salary:"", msg:false, validate:["numeric"]},
            currency: {status:false, val:oldJobData.value.currency?oldJobData.value.currency:"", msg:false, validate:["safeInput"]},
            reqFeedback : {status:false, val:"", msg:false},
            showSubmit : {status:false, val:true}
        });

        const jobTypeOptions = ref([
            {value:"full_time",alias:"Full-time"},
            {value:"part_time",alias:"Part-time"},
            {value:"consultancy",alias:"Consultancy"},
            {value:"freelance",alias:"Freelance"},
            {value:"internship",alias:"Internship"},
            {value:"contract",alias:"Contract"},
            {value:"volunteer",alias:"Volunteer"}
        ]);

        const placeholderOptions = {
            full_time:"salary per annum",
            part_time:"salary per annum",
            consultancy:"consultancy fee",
            freelance:"freelance fee",
            internship:"salary per annum",
            contract:"contract fee",
            volunteer:"volunteer fee"
        }

        const currencyList = [
            {value:"naira",alias:"Naira"},
            {value:"cedis",alias:"Cedis"},
            {value:"dollar",alias:"Dollar"},
            {value:"pound",alias:"Pound"},
            {value:"euro",alias:"Euro"}
        ]


        // start create  JD
        async function updateJobOptions(){

            // start the action of making a submission, and show loader
            fv.showSubmit.val = false;

            // load the form data , and append all the necessary fields
            let formData = new FormData();
            formData.append("fv_jobLocation", fv.jobLocation.val);
            formData.append("fv_jobType", fv.jobType.val);
            formData.append("fv_salaryStatus", fv.salaryStatus.val);
            formData.append("fv_salary", fv.salaryStatus.val=="disclose_salary"?fv.salary.val:0);
            formData.append("fv_currency", fv.currency.val );

            // post the data using axios
            try{

                const response = await axios.put(
                    coreUrl.backendUrl+'update_job_ad_options/'+props.currJobId,
                    formData,
                    {
                        withCredentials: true,
                        headers:{
                           "Content-Type": "multipart/form-data"
                        }
                    }
                );

                // If successful, get the resppnse data
                const responseData = response.data;

                const newJobAd = oldJobData.value;

                newJobAd.set_job_options = "yes";

                console.log(newJobAd);

                store.dispatch("recruitment/executeSetJobAd",newJobAd);


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = responseData.message.req_msg;
                fv.reqFeedback.val = "success";
                fv.showSubmit.status = false;

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;


            }catch(error){

                // load the error to the appropriate field
                const fv_msg = error.response.data.message.fv_msg;
                const req_msg = error.response.data.message.req_msg;

                for(const key in fv_msg){
                    if(fv[key]){
                        fv[key]["status"] = false;
                        fv[key]["msg"] = fv_msg[key];
                    }
                }


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = req_msg;
                fv.reqFeedback.val = "danger";

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;

            }

        }
        // end edit JD


        return {
            fv,
            validate,
            currencyList,
            locationOptions,
            jobTypeOptions,
            placeholderOptions,
            updateJobOptions
        }

    }
}


</script>
