<template>
    <!-- form -->
    <form class="validate-form">
    <!-- filter questions -->
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Select third party company</h4>
        </template>
        <template #default>


                <div class="row">
                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" for="basicSelect">Select 3rd party organization | <span class="text-primary">Register new organization. </span></label>
                            <select class="form-select" id="basicSelect">
                                <option>Select</option>
                                <option>Existing organization</option>
                                <option>Register new organization</option>
                            </select>
                        </div>
                    </div>
                </div>


                <div class="col-12">
                    <base-button btnColor="primary" >Set third-party organization</base-button>
                </div>



        </template>
    </base-card-plain>
    <!-- / filter questions -->






    </form>
    <!--/ form -->
</template>

<script>
// import components
import BaseCardPlain from '@/components/ui/BaseCardPlain.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

export default{
    props:{
        useExistingJD:{
            default:false,
            type: Boolean,
            required: false
        }
    },
    components:{
        BaseCardPlain,
        BaseButton
    }
}


</script>
